<template>
    <v-container fluid class="mt-1 mb-5">
        <v-row>
            <v-col cols="12">
                <div class="d-flex">
                    <breadcrumb :breadcumbs="breadcumbs"></breadcrumb>
                    <v-spacer></v-spacer>
                </div>
            </v-col>
        </v-row>
        <v-row class="mt-4">
            <v-col cols="12">
                <v-tabs
                    v-model="tab"
                    color="#027fb3"
                    class="round_top col-sm-no-padding"
                    icons-and-text
                    show-arrows
                >
                    <v-tabs-slider color="primary"></v-tabs-slider>
                    <v-tab
                        v-for="(tab, index) in tabs"
                        :key="index"
                        :href="'#tab-'+tab.int1"
                        active-class="active-tab"
                    >
                        {{ tab.str1 }}
                    </v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                    <v-tab-item
                        value="tab-1"
                    >
                        <ledger-budget></ledger-budget>
                    </v-tab-item>
                    <v-tab-item
                        value="tab-2"
                    >
                        <general-ledger-summary-report></general-ledger-summary-report>
                    </v-tab-item>
                    <v-tab-item
                        value="tab-3"
                    >
                        <general-ledger-budget-all></general-ledger-budget-all>
                    </v-tab-item>
                    <v-tab-item
                        value="tab-4"
                    >
                        <general-ledger-report></general-ledger-report>
                    </v-tab-item>
                    <v-tab-item
                        value="tab-5"
                    >
                        <general-ledger-report-activity></general-ledger-report-activity>
                    </v-tab-item>
                    <v-tab-item
                        value="tab-6"
                    >
                        <general-ledger-report-activity-all></general-ledger-report-activity-all>
                    </v-tab-item>                    
                    <v-tab-item
                        value="tab-7"
                    >
                        <activity-ledger-budget></activity-ledger-budget>   
                    </v-tab-item>
                    <v-tab-item
                        value="tab-8"
                    >   
                        <request-pencairan></request-pencairan>
                    </v-tab-item>
                    <v-tab-item
                        value="tab-9"
                    >
                        <payment-pencairan></payment-pencairan>
                    </v-tab-item>
                    <v-tab-item
                        value="tab-10"
                    >
                        <realisasi-anggaran></realisasi-anggaran>
                    </v-tab-item>
                    <v-tab-item
                        value="tab-11"
                    >
                        <report-realisasi-vs-anggaran></report-realisasi-vs-anggaran>
                    </v-tab-item>
                </v-tabs-items>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import {entity} from "@/backend-api/entity/master"
import {ledger_coa} from "@/backend-api/ledger/coa/index"
import {office} from "@/backend-api/office/index"
import { Money } from 'v-money'
import {gl_journal_voucher} from "@/backend-api/ledger/transaction/gl_journal_voucher"
import GeneralLedgerBudgetAll from "./GeneralLedgerBudgetAll.vue"
import GeneralLedgerReport from "./GeneralLedgerReport.vue"
import GeneralLedgerSummaryReport from "./GeneralLedgerSummaryReport.vue"
import GeneralLedgerReportActivity from "./GeneralLedgerReportActivity.vue"
import ActivityLedgerBudget from "./ActivityLedgerBudget.vue"
import RequestPencairan from "./RequestPencairan.vue"
import PaymentPencairan from "./PaymentPencairan.vue"
import LedgerBudget from "./LedgerBudget.vue"
import RealisasiAnggaran from "./RealisasiAnggaran.vue"
import {appl_constant} from "@/backend-api/appl_constant/index"
import {env_conf} from "@/backend-api/env_conf/index"
import Breadcrumb from "@/components/Breadcrumb.vue"
import ReportRealisasiVsAnggaran from "./ReportRealisasiVsAnggaran.vue"
import GeneralLedgerReportActivityAll from "./GeneralLedgerReportActivityAll.vue"

export default {
    components: {
        Money, GeneralLedgerSummaryReport, GeneralLedgerReport, GeneralLedgerReportActivity, ActivityLedgerBudget, RequestPencairan, PaymentPencairan, LedgerBudget, RealisasiAnggaran, Breadcrumb, ReportRealisasiVsAnggaran, GeneralLedgerBudgetAll, GeneralLedgerReportActivityAll
    },
    data() {
        return {
            tab: '',
            tabs: [],
            loading: false,
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            timeout: 7500,
            search: '',
            text_dialog:'',
            dialog: false,
            money: {
				decimal: '.',
				thousands: ',',
				prefix: '',
				suffix: '',
				precision: 0,
				masked: false
			},
            breadcumbs: [
            {
                    text: 'Finance',
                    disabled: false,
                    href: '/admin/finance',
                },
                {
                    text: 'Budget',
                    disabled: false,
                    href: '/admin/finance/budget-finance/budget',
                },
                {
                    text: 'Report Budget & Realisasi',
                    disabled: true,
                }
            ],
        }
    },
    computed:{
    },
    async mounted(){
        this.$store.dispatch('setOverlay', true)
        await this.getApplConstant()
        this.$store.dispatch('setOverlay', false)
    },
    methods: {
        async getEnvConf(var_id){
            var respDatamnCOA = await env_conf.getEnvVarConfUser(`?entity_id=GKI&appl_id=${this.$route.name}&var_id=${var_id}&groupid=${this.$store.state.user.group}`, null, false, false, false)
            if (respDatamnCOA.status === 200) {
                var menu = respDatamnCOA.data.data ? respDatamnCOA.data.data : '' 

                return menu
            } else {
                return false
            }
        },
        async getApplConstant(){
            var respDataTabs = await appl_constant.fetchApplConstant("?key_value=BUDGET&str2=REPORT BUDGET DAN REALISASI&sort_order=asc&order_by=int1", null, false, false, false)
            console.log('respDataTabs')
            console.log(respDataTabs)
            if (respDataTabs.status === 200) {
                var tabs = respDataTabs.data.data
                for (let index = 0; index < tabs.length; index++) {
                    const element = tabs[index];
                    const key_code = await this.getEnvConf(element.key_code)
                    console.log('key_code')
                    console.log(key_code)
                    if (key_code) {
                        tabs.splice(index, 1);
                    }
                }
                this.tabs = tabs
                console.log('tabs')
                console.log(this.tabs);
            }
        },
    },
    watch: {
    }
}
</script>
  
<style>
.v-card--reveal {
    align-items: center;
    bottom: 0;
    justify-content: center;
    opacity: .9;
    position: absolute;
    width: 100%;
}
.tr_datatable{
  background-color: #F5F7F8 !important;
}
</style>